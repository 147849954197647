import React from 'react';
import { Helmet } from 'react-helmet';

const Conditions = () => {
  return (
    <main className='containerPrivacyConditions'>
      <div className='privacyConditionsWrapper'>
        <Helmet>
          <title>Regulamin - Art Of Product Building</title>
          <meta
            name='description'
            content='Regulamin - Art Of Product Building'
          />
        </Helmet>
        <h1 className='titlePrivacyConditions'>Regulamin Wydarzenia</h1>
        <p className='pSpace'>
          Organizatorem wydarzenia "Art of Product Building" (dalej zwany
          "Wydarzeniem") jest DevCodi Sp. z o.o., z siedzibą Rzeszowie, ul.
          Adama Matuszczaka 14, 35-083 Rzeszów, zarejestrowanym w Krajowym
          Rejestrze Sądowym pod numerem 0000899127 (dalej zwany
          "Organizatorem").
        </p>
        <p className='pSpace'>
          Celem Wydarzenia jest umożliwienie uczestnikom zdobycia wiedzy i
          praktycznych umiejętności z zakresu tworzenia produktów, a także
          nawiązanie kontaktów biznesowych i networking.
        </p>
        <p className='pSpace'>
          Uczestnictwo w Wydarzeniu jest bezpłatne i wymaga uprzedniej
          rejestracji za pośrednictwem strony internetowej{' '}
          <a href='https://app.evenea.pl/event/art-of-product-building'>
            https://app.evenea.pl/event/art-of-product-building
          </a>
        </p>
        <p className='pSpace'>
          Organizator zastrzega sobie prawo do odwołania lub zmiany daty i
          miejsca Wydarzenia w każdej chwili.
        </p>
        <p className='pSpace'>
          Uczestnicy Wydarzenia zobowiązani są do przestrzegania zasad
          wynikających z niniejszego regulaminu oraz przepisów prawa
          obowiązujących w Polsce.
        </p>
        <p className='pSpace'>
          Organizator nie ponosi odpowiedzialności za szkody wyrządzone przez
          uczestników Wydarzenia podczas trwania wydarzenia.
        </p>
        <p className='pSpace'>
          Organizator zastrzega sobie prawo do wykonywania zdjęć, nagrań wideo i
          audio podczas Wydarzenia, a także do ich wykorzystywania do celów
          promocyjnych.
        </p>
        <p className='pSpace'>
          Dane osobowe uczestników przetwarzane będą przez Organizatora w celu
          umożliwienia uczestnictwa w Wydarzeniu. Dane te mogą być przekazywane
          sponsorom Wydarzenia w celach promocyjnych i marketingowych, a także w
          celu nawiązania współpracy. Uczestnicy mają prawo do wglądu i
          poprawiania swoich danych osobowych, a także do żądania ich usunięcia.
          Szczegóły przetwarzania danych znajdują się w polityce prywatności:{' '}
          <a href='https://artofproductbuilding.com/polityka-prywatnosci'>
            https://artofproductbuilding.com/polityka-prywatnosci
          </a>
        </p>
        <p className='pSpace'>
          Organizator zastrzega sobie prawo do zmiany regulaminu w każdej
          chwili. Wszelkie zmiany zostaną opublikowane na stronie Facebook
          Wydarzenia dostępnej pod adresem:{' '}
          <a href='https://www.facebook.com/artofproductbuilding'>
            https://www.facebook.com/artofproductbuilding
          </a>
        </p>
        <p className='pSpace'>
          W przypadku jakichkolwiek pytań lub wątpliwości dotyczących
          Wydarzenia, uczestnicy mogą kontaktować się z Organizatorem za
          pośrednictwem adresu e-mail:{' '}
          <a href='mailto:kontakt@artofproductbuilding.com'>
            kontakt@artofproductbuilding.com
          </a>
        </p>
        <p>Regulamin niniejszy wchodzi w życie z dniem 25.04.2023.</p>
      </div>
    </main>
  );
};

export default Conditions;
