import React from 'react';
import logoLight from '../img/logo-light.png';
import logoDark from '../img/logo-dark.png';
import {
  StyledLoginIcon,
  StyledArrowForwardIosIcon,
} from '../styles/StyledIcons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.pathname);

  function handleClick(event) {
    event.preventDefault(); // zapobiega domyślnej akcji linku
    const onePageLink = event.target.getAttribute('href'); // pobiera wartość atrybutu href

    if (location.pathname !== '/' && onePageLink) {
      navigate('/');
      setTimeout(() => {
        const onePageLink = event.target.getAttribute('href'); // pobiera wartość atrybutu href
        const element = document.querySelector(onePageLink); // znajduje element o podanym identyfikatorze
        element.scrollIntoView({ behavior: 'smooth' }); // przewija do elementu z efektem "smooth"})
      }, 500);
      return;
    }

    const element = document.querySelector(onePageLink); // znajduje element o podanym identyfikatorze
    element.scrollIntoView({ behavior: 'smooth' }); // przewija do elementu z efektem "smooth"})
  }

  const handleClickUp = () => {
    window.scrollTo(0, 0);
  };

  return (
    <header>
      <div className='main-menu'>
        <nav className='navbar navbar-expand-lg navbar-dark'>
          <div className='container'>
            <Link
              className='navbar-brand'
              to='/'
              onClick={() => window.scrollTo(0, 0)}
            >
              <img src={logoDark} className='logo logo-dark' alt='logo' />
              <img src={logoLight} className='logo logo-light' alt='logo' />
            </Link>
            <button
              className='navbar-toggler'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarNavDropdown'
              aria-controls='navbarNavDropdown'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse' id='navbarNavDropdown'>
              <ul className='navbar-nav'>
                <li className='nav-item'>
                  <a className='nav-link' href='#about' onClick={handleClick}>
                    Strona główna
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    href='#features'
                    onClick={handleClick}
                  >
                    Co nas wyróżnia
                  </a>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/harmonogram'
                    onClick={handleClickUp}
                    className='nav-link'
                  >
                    Harmonogram
                  </Link>
                </li>
                <li className='nav-item'>
                  <a className='nav-link' href='#team' onClick={handleClick}>
                    Zespół
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link' href='#faq' onClick={handleClick}>
                    FAQ
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    href='#kontakt-section'
                    onClick={handleClick}
                  >
                    Kontakt
                  </a>
                </li>
                <li className='nav-item' id='kontakt'>
                  <a
                    className='nav-link button-1'
                    href='https://app.evenea.pl/event/art-of-product-building/'
                    target='_blank'
                  >
                    Zapisz się
                    <StyledArrowForwardIosIcon />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
