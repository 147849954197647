import React from 'react';
import { Link } from 'react-router-dom';
import logoDark from '../img/logo-dark.png';

const Footer = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className='footer'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-3 col-md-6'>
            <div className='footer-box'>
              <div className='logo'>
                <img src={logoDark} alt='Logo' className='bg' />
              </div>
              <p className='text'>
                Art Of Product Building - uczymy tworzyć produkty technologiczne
              </p>
            </div>
          </div>
          <div className='col-xl-9'>
            <div className='row row-footer'>
              <div className='col-xl-3 col-lg-4 col-sm-6'>
                <div className='footer-box'>
                  <h4 className='lasthead'>Menu</h4>
                  <ul className='footer-link'>
                    <li className='nav-item'>
                      <a href='#about'>Strona głównna</a>
                    </li>
                    <li className='nav-item'>
                      <a href='#features'>Co nas wyróżnia</a>
                    </li>
                    <li className='nav-item'>
                      <a href='#plan'>Harmonogram</a>
                    </li>
                    <li className='nav-item'>
                      <a href='#team'>Zespół </a>
                    </li>
                    <li className='nav-item'>
                      <a href='#faq'>FAQ</a>
                    </li>
                    <li className='nav-item'>
                      <a href='#kontakt-section'>Kontakt</a>
                    </li>
                    <li className='nav-item'>
                      <a href='https://app.evenea.pl/event/art-of-product-building/'>
                        Zapisz się
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-xl-3 col-lg-4 col-sm-6'>
                <div className='footer-box'>
                  <h4 className='lasthead'>Polityka</h4>
                  <ul className='footer-link'>
                    <li>
                      <Link to='/regulamin' onClick={handleClick}>
                        Regulamin
                      </Link>
                    </li>
                    <li>
                      <Link to='/polityka-prywatnosci' onClick={handleClick}>
                        Polityka prywatności
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-xl-3 col-lg-4 col-sm-6'>
                <div className='footer-box'>
                  <h4 className='lasthead'>Kontakt</h4>
                  <ul className='footer-link'>
                    <li>
                      <a href='mailto:kontakt@artofproductbuilding.com'>
                        kontakt@artofproductbuilding.com
                      </a>
                    </li>
                    <li>
                      <a href='tel:+48 575 472 264'>+48 575 472 264</a>
                    </li>
                    <li>
                      <a href='tel:+48 535 077 291'>+48 535 077 291</a>
                    </li>
                    <li>
                      <a href='tel:+48 793 751 966'>+48 793 751 966</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <div className='footer-bottom'>
              <div className='content'>
                <p className='text'>
                  <span style={{ marginRight: 5, display: 'inline' }}>
                    Copyright &copy; 2023. Wszelkie prawa zastrzeżone
                  </span>
                  <a href='https://divstack.pl/' target='_blank'>
                    Divstack
                  </a>
                </p>
              </div>
              <div className='social-style'>
                <a href='https://www.facebook.com/artofproductbuilding'>
                  <i className='fab fa-facebook-f'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
