import React from 'react';
// import '../../App.css';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <main className='containerPrivacyConditions'>
      <div className='privacyConditionsWrapper'>
        <Helmet>
          <title>Polityka Prywatności - Art Of Product Building</title>
          <meta
            name='description'
            content='Polityka Prywatności - Art Of Product Building'
          />
        </Helmet>
        <h1 className='titlePrivacyConditions'>Polityka Prywatności</h1>
        <div className='singleSection'>
          <h3>Polityka prywatności Art of Product Building</h3>
          <p>
            Niniejsza polityka prywatności określa sposób, w jaki
            wykorzystujemy, przetwarzamy oraz chronimy dane osobowe uczestników
            wydarzenia Art of Product Building. Zapewniamy, że Twoja prywatność
            jest dla nas niezwykle ważna i gwarantujemy, że Twoje dane osobowe
            będą przetwarzane zgodnie z obowiązującymi przepisami prawa o
            ochronie danych osobowych.
          </p>
        </div>
        <div className='singleSection'>
          <h3>Kto jest administratorem Twoich danych osobowych?</h3>
          <p>
            Administratorem Twoich danych osobowych jest firma DevCodi Sp. z
            o.o., z siedzibą w Rzeszowie, ul. Adama Matuszczaka 14, 35-083
            Rzeszów.
          </p>
        </div>
        <div className='singleSection'>
          <h3>Jakie dane osobowe przetwarzamy i w jakim celu?</h3>
          <p>
            Podczas rejestracji na wydarzenie Art of Product Building będziemy
            przetwarzać Twoje dane osobowe, takie jak imię, nazwisko, adres
            e-mail, numer telefonu. Dane te będą przetwarzane w celu organizacji
            wydarzenia oraz w celu przekazywania informacji o wydarzeniu i
            zaproszeń na przyszłe edycje wydarzenia.
          </p>
        </div>
        <div className='singleSection'>
          <h3>Czy przekazujemy Twoje dane osobowe innym podmiotom?</h3>
          <p>
            Tak, podczas wydarzenia Art of Product Building Twoje dane osobowe
            będą przekazywane sponsorom wydarzenia. Firmy będące sponsorem będą
            przetwarzać Twoje dane osobowe w celu zaproszenia Cię do współpracy
            oraz w celu przekazania informacji o swoich produktach i usługach.
            Wszystkie sponsorzy wydarzenia są zobowiązani do przetwarzania
            Twoich danych osobowych zgodnie z obowiązującymi przepisami prawa o
            ochronie danych osobowych.
          </p>
        </div>
        <div className='singleSection'>
          <h3>Jak długo będziemy przetwarzać Twoje dane osobowe? </h3>
          <p>
            Twoje dane osobowe będą przechowywane i przetwarzane przez okres
            niezbędny do realizacji celów, dla których zostały zebrane, zgodnie
            z obowiązującymi przepisami prawa o ochronie danych osobowych.
          </p>
        </div>
        <div className='singleSection'>
          <h3>
            Jakie masz prawa związane z przetwarzaniem Twoich danych osobowych?
          </h3>
          <p>
            Masz prawo do sprostowania danych, ich usunięcia oraz ograniczenia
            ich przetwarzania.
          </p>

          <p>
            W razie jakichkolwiek pytań lub wątpliwości dotyczących
            przetwarzania Twoich danych osobowych przez Art of Product Building,
            prosimy o kontakt na adres e-mail:{' '}
            <a href='mailto:kontakt@artofproductbuilding.com'>
              kontakt@artofproductbuilding.com
            </a>
          </p>
        </div>
        <div className='singleSection'>
          <h3>Dane kontaktowe administratora:</h3>
          <ul>
            <li>DevCodi Sp. z o.o.</li>
            <li>ul. Adama Matuszczaka 14</li>
            <li>35-083 Rzeszów</li>
            <li>NIP: 8133859866</li>
            <li>
              Email:{' '}
              <a href='mailto:kontakt@artofproductbuilding.com'>
                kontakt@artofproductbuilding.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
