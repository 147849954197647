import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Main from './components/Main';
import './styles/index.scss';
import Footer from './components/Footer';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import Conditions from './components/pages/Conditions';
import Schedule from './components/pages/Schedule';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path='/' element={<Main />} />
          <Route
            exact
            path='/polityka-prywatnosci'
            element={<PrivacyPolicy />}
          />
          <Route exact path='/harmonogram' element={<Schedule />} />
          <Route exact path='/regulamin' element={<Conditions />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
