import React from 'react';
import { Helmet } from 'react-helmet';
import { Plan } from '../plan/Plan'

const Schedule = () => {
  return (
    <>
      <Helmet>
        <title>Harmonogram - Art Of Product Building</title>
        <meta
          name='description'
          content='Harmonogram - Art Of Product Building'
        />
      </Helmet>
      <main className='sheduleContainer'>
        <div className='sheduleContent'>
          <div className='calculate' id='calculate'>
            <Plan/>
          </div>
        </div>
      </main>
    </>
  );
};

export default Schedule;
