import React from 'react';

export const Plan = () => {
  return (
    <>
        <div className='container plan-container' id='plan'>
            <div className='row align-items-center justify-content-between plan-header'>
            <div className='col-xl-10 col-lg-12 text-center'>
                <div className='section-head'>
                <h3
                    className='subtitle wow fadeInDown'
                    data-wow-duration='0.4s'
                    data-wow-delay='0.4s'
                >
                    Harmonogram
                </h3>
                <h2
                    className='title wow fadeInDown'
                    data-wow-duration='0.4s'
                    data-wow-delay='0.4s'
                >
                    Przebieg Hackathonu
                </h2>
                <p
                    className='text wow fadeInDown'
                    data-wow-duration='0.4s'
                    data-wow-delay='0.4s'
                >
                    W czasie 24h uczestnicy będą mieli czas nie tylko na prace
                    nad aplikacją, ale w trakcie odbędzie się kilka prezentacji
                    naszych mentorów
                </p>
                </div>
            </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='total-box'>
                    <div className='price-box'>
                        <div className='row justify-content-center align-items-center'>
                        <h2 className='plan-deadline'>20 - 21 maja 2023</h2>
                        <h2 className='plan-localization'>Rzeszów WSIiZ</h2>
                        <table className='plan-table'>
                            <tbody>
                                <tr className='table-row'>
                                    <td>9:00</td>
                                    <td style={{textAlign: 'left'}}>Start rejestracji (Czas na przygotowanie stanowisk)</td>
                                </tr>
                                <tr className='table-row'>
                                    <td>10:00</td>
                                    <td style={{textAlign: 'left'}}>Oficjalne otwarcie hackathonu</td>
                                </tr>
                                <tr className='table-row'>
                                    <td>10:15</td>
                                    <td style={{textAlign: 'left'}}>
                                        Prezentacje:<br/>
                                        <ul>
                                            <li>”Najczęściej popełniane błędy na hackathonach” - Hubert Siast</li>
                                            <li>”Analiza domeny kluczem do zbudowania przewagi konkurencyjnej” - Kamil Bączek</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr className='table-row'>
                                    <td>11:00</td>
                                    <td style={{textAlign: 'left'}}>Rozpoczęcie konsultacji z mentorami</td>
                                </tr>
                                <tr className='table-row'>
                                    <td>14:00</td>
                                    <td style={{textAlign: 'left'}}>
                                        Prezentacje:<br/>
                                        <ul>
                                            <li>“Hackathon wart 2mln zł. Relacje w biznesie i startupach” - Paweł Janda</li>
                                            <li>”Low code w praktyce” - Bartosz Hajder</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr className='table-row'>
                                    <td>15:00</td>
                                    <td style={{textAlign: 'left'}}>Pizza! :)</td>
                                </tr>
                                <tr className='table-row'>
                                    <td>19:00</td>
                                    <td style={{textAlign: 'left'}}>
                                         Prezentacje:<br/>
                                        <ul>
                                            <li>”O MVP słów kilka” - Jakub Lula</li>
                                            <li>”Narzędzie do budowy produktów” - Karol Kibałko</li>
                                            <li>”Pozyskiwanie funduszy” - Mariusz Wadas</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr className='table-row'>
                                    <td>9:00</td>
                                    <td style={{textAlign: 'left'}}>Przejście mentorów po stanowiskach</td>
                                </tr>
                                <tr className='table-row'>
                                    <td>10:00</td>
                                    <td style={{textAlign: 'left'}}>KONIEC PRAC</td>
                                </tr>
                                <tr className='table-row'>
                                    <td>10:15</td>
                                    <td style={{textAlign: 'left'}}>Prezentacje prac konkursowych przed Jury</td>
                                </tr>
                                <tr className='table-row'>
                                    <td>11:00</td>
                                    <td style={{textAlign: 'left'}}>“Jak unikać błędów podczas budowy startupu?” - Sławomir Paśko</td>
                                </tr>
                                <tr className='table-row'>
                                    <td>12:00</td>
                                    <td style={{textAlign: 'left'}}>Ogłoszenie wyników i wręczenie nagród</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};