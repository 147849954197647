import React from 'react';
import heroImg from '../img/hero-img.png';
import counter from '../img/counter-1.png';
import creditCard from '../img/credit_card.svg';
import mentors from '../img/counter-3.png';
import presentation from '../img/presentation.svg';
import bussines from '../img/Business.svg';
import networking from '../img/networking.svg';
import support from '../img/support.svg';
import skills from '../img/skills.svg';
import offersImg from '../img/img-offers.png';
import person_team1 from '../img/imgTeam/hubert_siast.jpg';
import person_team2 from '../img/imgTeam/kamil_baczek.jpg';
import person_team3 from '../img/imgTeam/mariusz_wadas.jpg';
import pawel_janda from '../img/imgTeam/pawel_janda.jpg';
import ceo_neti from '../img/imgTeam/ceo-neti.jpg';
import person_team5 from '../img/imgTeam/norbert.jpg';
import mateusz from '../img/imgTeam/mateusz.png';
import person_team6 from '../img/imgTeam/marek_jaszukj.jpg';
import fdot1 from '../img/fdot-1.png';
import fdot2 from '../img/fdot-2.png';
import fdot3 from '../img/fdot-3.png';
import faqinfo from '../img/faq-icon.png';
import cetusLogo from '../img/sponsors/cetus logo białe tło-01.png';
import logoDivstack from '../img/sponsors/logo-divstack.png';
import logoMobitouch from '../img/sponsors/logo-mobitouch.svg';
import logoSoftwaremind from '../img/sponsors/logo-softwaremind.svg';
import logoWsiz from '../img/sponsors/wsiz-logo.png';
import logoDevCodi from '../img/sponsors/devcodi-logo.png';
import neti from '../img/sponsors/neti.png';
import { Helmet } from 'react-helmet';
import { StyledArrowUpIcon, StyledSendIcon } from '../styles/StyledIcons';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useForm } from 'react-hook-form';
import * as emailjs from 'emailjs-com';
import { Plan } from '../components/plan/Plan';
const Swal = require('sweetalert2');

const Main = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    emailjs
      .sendForm(
        'service_zvbd513',
        'template_o17z9j2',
        '.contact_form',
        `${process.env.REACT_APP_EMAIL_USERID}`
      )
      .then(() => {
        Swal.fire('Gratulacje!', 'Wiadomość została do nas wysłana', 'success');
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          title: 'Error!',
          text: 'Coś poszło nie tak. Wiadomość nie została wysłana.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      });
  };

  return (
    <main>
      <div className='banner' id='about'>
        <Helmet>
          <title>
            Art Of Product Building Hackathon - dobre praktyki przy tworzeniu
            produktów technologicznych
          </title>
          <meta
            name='description'
            content='Strona główna - Art Of Product Building'
          />
        </Helmet>
        <div className='hero-area'>
          <div className='container hero-wrapper'>
            <div className='row-fixed align-items-center'>
              <div className='col-xl-7'>
                <div className='banner-content'>
                  <h3
                    className='subtitle wow fadeInDown'
                    data-wow-duration='0.3s'
                    data-wow-delay='0.3s'
                  >
                    Hackathon Rzeszów
                  </h3>
                  <h1
                    className='head wow fadeInDown'
                    data-wow-duration='0.3s'
                    data-wow-delay='0.3s'
                  >
                    Art Of Product Building 2023
                  </h1>
                  <div
                    className='button-box wow fadeInUp'
                    data-wow-duration='0.3s'
                    data-wow-delay='0.3s'
                  >
                    <p
                      className='text wow fadeInDown mb-2'
                      data-wow-duration='0.4s'
                      data-wow-delay='0.4s'
                    >
                      Nauczymy Cię dobrych praktyk tworzenia produktów
                      technologicznych 🔥
                    </p>
                    <a
                      href='https://app.evenea.pl/event/art-of-product-building/'
                      className='one button-2'
                      target='_blank'
                    >
                      Zapisz się
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='circles'>
              <img className='hero-img' src={heroImg}></img>
              <div className='circle circle-1'></div>
              <div className='circle circle-2'></div>
            </div>
          </div>
        </div>
      </div>

      <div className='counter'>
        <div className='container'>
          <div className='row-fixed align-items-center justify-content-center display'>
            <div
              className='col-xl-4 col-md-6 wow fadeInDown'
              data-wow-duration='0.3s'
              data-wow-delay='0.3s'
            >
              <div className='page-counter'>
                <div className='thmb'>
                  <img src={counter} alt='' />
                </div>
                <div className='counter-item'>
                  <h2 className='title'>
                    <span className='count-num'>50</span>
                  </h2>
                  <p className='text'>Ilość uczestników</p>
                </div>
              </div>
            </div>

            <div
              className='col-xl-4 col-md-6 wow fadeInDown'
              data-wow-duration='0.5s'
              data-wow-delay='0.5s'
            >
              <div className='page-counter'>
                <div className='thmb'>
                  <img src={mentors} alt='' />
                </div>
                <div className='counter-item'>
                  <h2 className='title'>
                    <span className='count-num'>5</span>
                  </h2>
                  <p className='text'>Mentorów</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='loon' id='features'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-xl-6 col-lg-10'>
              <div className='section-head'>
                <h3
                  className='subtitle wow fadeInDown information'
                  data-wow-duration='0.4s'
                  data-wow-delay='0.4s'
                >
                  Najważniejsze informacje
                </h3>
                <h2
                  className='title wow fadeInDown'
                  data-wow-duration='0.4s'
                  data-wow-delay='0.4s'
                >
                  Co nas wyróżnia?
                </h2>
                <p
                  className='text wow fadeInDown'
                  data-wow-duration='0.4s'
                  data-wow-delay='0.4s'
                >
                  sprawdź się i zwiększ swoje umiejętności w swoim zawodzie
                </p>
              </div>
              <ul className='list'>
                <li
                  className='list-item wow fadeInDown'
                  data-wow-duration='0.4s'
                  data-wow-delay='0.4s'
                >
                  <div className='thumb'>
                    <img src={presentation} alt='' />
                  </div>
                  <div className='content'>
                    <h4 className='lasthead'>Wartościowe prezentacje</h4>
                    <p className='text'>
                      Prezenacje przekazujące dobre praktyki tworzenia produktów
                    </p>
                  </div>
                </li>
                <li
                  className='list-item wow fadeInDown'
                  data-wow-duration='0.4s'
                  data-wow-delay='0.4s'
                >
                  <div className='thumb'>
                    <img src={support} alt='' />
                  </div>
                  <div className='content'>
                    <h4 className='lasthead'>
                      Wsparcie mentorów podczas hackathonu
                    </h4>
                    <p className='text'>
                      Mentorzy w każdej chwili będą odpowiedzieć na twoje
                      pytania i udzielać Ci konsultacji
                    </p>
                  </div>
                </li>
                <li
                  className='list-item wow fadeInDown'
                  data-wow-duration='0.4s'
                  data-wow-delay='0.4s'
                >
                  <div className='thumb'>
                    <img src={bussines} alt='' />
                  </div>
                  <div className='content'>
                    <h4 className='lasthead'>Skupienie na biznesie</h4>
                    <p className='text'>
                      Skupimy się nie tylko na programowaniu, ale na pokazaniu
                      jak z twojego pomysłu możesz zrobić biznes
                    </p>
                  </div>
                </li>
                <li
                  className='list-item wow fadeInDown'
                  data-wow-duration='0.4s'
                  data-wow-delay='0.4s'
                >
                  <div className='thumb'>
                    <img src={networking} alt='' />
                  </div>
                  <div className='content'>
                    <h4 className='lasthead'>Networking</h4>
                    <p className='text'>
                      Wydarzenie to idealna okazja do poznania osób, które chcą
                      rozwijać swoje startupy
                    </p>
                  </div>
                </li>
                <li
                  className='list-item wow fadeInDown'
                  data-wow-duration='0.4s'
                  data-wow-delay='0.4s'
                >
                  <div className='thumb'>
                    <img src={skills} alt='' />
                  </div>
                  <div className='content'>
                    <h4 className='lasthead'>
                      Szansa na pokazanie swoich umiejętności
                    </h4>
                    <p className='text'>
                      Wydarzenie to szansa na zaprezentowaniu swoich
                      umiejętności przed sponsorami oraz inwestorami
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className='col-xl-6 d-none d-xl-block'>
              <div
                className='pic wow fadeInRight'
                data-wow-duration='0.8s'
                data-wow-delay='0.5s'
              ></div>
              <div className='col-xl-6 d-none d-xl-block'>
                <div
                  className='pic wow fadeInRight'
                  data-wow-duration='0.8s'
                  data-wow-delay='0.5s'
                >
                  <img src={offersImg} alt='' className='offers-img' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='calculate' id='calculate'>
        <Plan />
      </div>

      <div className='feature team-wrapper'>
        <div className='container'>
          <div className='row align-items-center justify-content-betweens'>
            <div className='col-12 text-center' id='team'>
              <div className='section-head margin-fixed'>
                <h3
                  className='subtitle wow fadeInDown'
                  id='howitworks'
                  data-wow-duration='0.4s'
                  data-wow-delay='0.4s'
                >
                  Team
                </h3>
                <h2
                  className='title wow fadeInDown'
                  data-wow-duration='0.4s'
                  data-wow-delay='0.4s'
                >
                  Organizatorzy i mentorzy
                </h2>
                <p
                  className='text wow fadeInDown'
                  data-wow-duration='0.4s'
                  data-wow-delay='0.4s'
                >
                  Nasz team składa się z osób, które mają doświadczenie
                  biznesowe w tworzeniu produktów technologicznych, którym chcą
                  się dzielić z innymi
                </p>
              </div>
            </div>
          </div>
          <div className='team-container'>
            <div className='cards'>
              <div className='single-card'>
                <div className='card'>
                  <img src={person_team1} className='card__image' alt='' />
                  <div className='card__overlay'>
                    <div className='card__header'>
                      <svg
                        className='card__arc'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path />
                      </svg>
                      <div className='card__header-text'>
                        <a
                          href='https://www.linkedin.com/in/hubertsiast/'
                          className='linkedin'
                          target='_blank'
                        >
                          <LinkedInIcon />
                        </a>
                        <div className='card-text-info'>
                          <h3 className='card__title'>Hubert Siast</h3>
                          <span className='card__status'>Business Developer w CetusPro</span>
                        </div>
                      </div>
                    </div>
                    <p className='card__description'>Business Developer w fimie CetusPro gdzie rozwijamy dedykowane oprogramowanie dla firm typu startup oraz dla korporacji. Zarządzam projektami, pasjonuję się marketingiem B2B oraz wspieram firmy naszych klientów w automatyzacji ich procesów biznesowych. </p>
                  </div>
                </div>
              </div>
              <div className='single-card'>
                <div className='card'>
                  <img src={person_team2} className='card__image' />
                  <div className='card__overlay'>
                    <div className='card__header'>
                      <svg
                        className='card__arc'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path />
                      </svg>
                      <div className='card__header-text'>
                        <a
                          href='https://www.linkedin.com/in/kamilbaczek/'
                          className='linkedin'
                          target='_blank'
                        >
                          <LinkedInIcon />
                        </a>
                        <div className='card-text-info'>
                          <h3 className='card__title'>Kamil Bączek</h3>
                          <span className='card__status'>
                            Software Architect
                          </span>
                        </div>
                      </div>
                    </div>
                    <p className='card__description'>
                      Kamil jest inżynierem i architektem oprogramowania.
                      Posiada bogate doświadczenie w projektowaniu i tworzeniu
                      rozwiązań cyfrowych, które łączą wiedzę z zakresu biznesu
                      i technologii. Kamil prowadzi także bloga, gdzie dzieli
                      się swoimi doświadczeniami i inspirowuje innych do
                      rozwijania swoich projektów.
                    </p>
                  </div>
                </div>
              </div>
              <div className='single-card'>
                <div className='card'>
                  <img src={person_team3} className='card__image' />

                  <div className='card__overlay'>
                    <div className='card__header'>
                      <svg
                        className='card__arc'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path />
                      </svg>
                      <div className='card__header-text'>
                        <a
                          href='https://www.linkedin.com/in/mariusz-wadas/'
                          className='linkedin'
                          target='_blank'
                        >
                          <LinkedInIcon />
                        </a>
                        <div className='card-text-info'>
                          <h3 className='card__title'>Mariusz Wadas</h3>
                          <span className='card__status'>CEO DevCodi</span>
                        </div>
                      </div>
                    </div>
                    <p className='card__description'>CEO DevCodi specjalizującej się w tworzeniu customowego oprogramowania. Pracował głównie w startupach technologicznych, dlatego dobrze zna proces szybkiego budowania produktów. W DevCodi opracowuje nowatorskie produkty i usługi, w odpowiedzi na potrzeby klientów.</p>
                  </div>
                </div>
              </div>
              <div className='single-card'>
                <div className='card'>
                  <img src={person_team6} className='card__image' alt='' />
                  <div className='card__overlay'>
                    <div className='card__header'>
                      <svg
                        className='card__arc'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path />
                      </svg>
                      <div className='card__header-text'>
                        <a
                          href='https://www.linkedin.com/in/marek-jaszuk-10374676'
                          className='linkedin'
                          target='_blank'
                          style={{ marginRight: '10px' }}
                        >
                          <LinkedInIcon />
                        </a>
                        <div className='card-text-info'>
                          <h3 className='card__title'>Marek Jaszuk</h3>
                          <span className='card__status'>
                            Nauczyciel akademicki, adiunkt WSIiZ
                          </span>
                        </div>
                      </div>
                    </div>
                    <p className='card__description'>
                      Nauczyciel akademicki, adiunkt w Wyższej Szkole
                      Informatyki i Zarządzania. Specjalista w dziedzinie
                      uczenia maszynowego i widzenia komputerowego. Wieloletni
                      opiekun Koła Naukowego Nowych Technologii
                      Programistycznych i mentor licznych projektów studenckich.
                    </p>
                  </div>
                </div>
              </div>
              <div className='single-card'>
                <div className='card'>
                  <img src={ceo_neti} className='card__image' />
                  <div className='card__overlay'>
                    <div className='card__header'>
                      <svg
                        className='card__arc'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path />
                      </svg>
                      <div className='card__header-text'>
                        <a
                          href='https://www.linkedin.com/in/slawomirpasko'
                          className='linkedin'
                          target='_blank'
                        >
                          <LinkedInIcon />
                        </a>
                        <div className='card-text-info'>
                          <h3 className='card__title'>Sławomir Paśko</h3>
                          <span className='card__status'>CEO & Founder Neti</span>
                        </div>
                      </div>
                    </div>
                    <p className='card__description'>Założyciel i CEO firmy Neti zajmującej się tworzeniem dedykowanego oprogramowania głównie dla klientów z Europy Zachodniej. Z branżą IT związany od ponad 20 lat; weteran, który przeszedł przez większość stanowisk dostępnych w "drabince kariery". Od kilku lat swoje zainteresowania zawodowe skupia na technologii blockchain i możliwościach jej zastosowania w rozwiązywaniu problemów biznesu.</p>
                  </div>
                </div>
              </div>
              <div className='single-card'>
                <div className='card'>
                  <img src={pawel_janda} className='card__image' />
                  <div className='card__overlay'>
                    <div className='card__header'>
                      <svg
                        className='card__arc'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path />
                      </svg>
                      <div className='card__header-text'>
                        <a
                          href='https://www.linkedin.com/in/pawel-janda'
                          className='linkedin'
                          target='_blank'
                        >
                          <LinkedInIcon />
                        </a>
                        <div className='card-text-info'>
                          <h3 className='card__title'>Paweł Janda</h3>
                          <span className='card__status'>
                            CEO Mobitouch
                          </span>
                        </div>
                      </div>
                    </div>
                    <p className='card__description'>Prezes zarządu Spółki Mobitouch sp. z o.o. specjalizującej się w produkcji oprogramowania dedykowanego i aplikacji mobilnych. Współzałożyciel oraz członek Rady Dyrektorów Organizacji Pracodawców Usług IT (SoDA), która zrzesza prawie 200 liczących się podmiotów z branży IT i jest największą tego typu instytucją w Polsce. Od kilku edycji jest członkiem kapituły konkursowej Mobile Trends Awards - najważniejszej imprezy branżowej dotyczącej rozwoju rynku mobilnego oraz nowoczesnych technologi</p>
                  </div>
                </div>
              </div>
              <div className='single-card'>
                <div className='card'>
                  <img src={person_team5} className='card__image' />
                  <div className='card__overlay'>
                    <div className='card__header'>
                      <svg
                        className='card__arc'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path />
                      </svg>
                      <div className='card__header-text'>
                        <a
                          href='https://www.linkedin.com/in/norbert-pisz-8ba76b54/'
                          className='linkedin'
                          target='_blank'
                        >
                          <LinkedInIcon />
                        </a>
                        <div className='card-text-info'>
                          <h3 className='card__title'>Norbert Pisz</h3>
                          <span className='card__status'>CEO CetusPro</span>
                        </div>
                      </div>
                    </div>
                    <p className='card__description'>
                      Właściciel cetuspro.com - firmy zajmującej się
                      wytwarzaniem dedykowanego oprogramowania dla startupów
                      oraz korporacji. Obecnie zarządza zespołem ponad 35
                      programistów. Pomaga startupom budować innowacyjne
                      rozwiązania technologiczne i uczestniczy w projektach
                      badawczo-rozwojowych. Inwestuje w startupy na wczesnym
                      etapie rozwoju oferując kapitał oraz wsparcie
                      merytoryczne. Twórca Cetus Academy - akademii
                      programowania i nowych technologii w której młodzi ludzie
                      zaczynają przygodę z technologią.
                    </p>
                  </div>
                </div>
              </div>
              <div className='single-card'>
                <div className='card'>
                  <img src={mateusz} className='card__image' />
                  <div className='card__overlay'>
                    <div className='card__header'>
                      <svg
                        className='card__arc'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path />
                      </svg>
                      <div className='card__header-text'>
                        <a
                          href='https://www.linkedin.com/in/mateusz-motyl-2635a2194'
                          className='linkedin'
                          target='_blank'
                        >
                          <LinkedInIcon />
                        </a>
                        <div className='card-text-info'>
                          <h3 className='card__title'>Mateusz Motyl</h3>
                          <span className='card__status'>Programista Full-stack w Neti</span>
                        </div>
                      </div>
                    </div>
                    <p className='card__description'>Profesjonalny programista full-stack z 3,5-letnim komercyjnym doświadczeniem. Wielokrotnie aktywnie uczestniczył w tworzeniu oprogramowania od podstaw. Jest przyzwyczajony do pracy w zespole i lubi dynamiczne tempo pracy nad ambitnymi projektami. Od zeszłego roku koncentruje się na technologii blockchain, rozwijając głównie produkty finansowe dla różnych EVM.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='faq' id='faq'>
        <img src={fdot1} alt='' className='fdot-1' />
        <img src={fdot2} alt='' className='fdot-2' />
        <img src={fdot3} alt='' className='fdot-3' />
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <div className='section-head'>
                <h2
                  className='title wow fadeInDown'
                  data-wow-duration='0.4s'
                  data-wow-delay='0.4s'
                >
                  Często Zadawane Pytania
                </h2>
                <p
                  className='text wow fadeInUp'
                  data-wow-duration='0.4s'
                  data-wow-delay='0.4s'
                >
                  Jeśli nie znalazłeś odpowiedzi na swoje pytanie, skontaktuj
                  się z nami pisząc na adres{' '}
                  <a href='mailto:kontakt@artofproductbuilding.com'>
                    kontakt@artofproductbuilding.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className='row align-items-center justify-content-between'>
            <div className='col-xl-4 d-none d-xl-block'>
              <div
                className='fpic wow fadeInLeft'
                data-wow-duration='0.4s'
                data-wow-delay='0.4s'
              ></div>
            </div>
            <div className='col-xl-8 faq-wrapper'>
              <div className='faq-box'>
                <div className='accordion' id='accordionExample'>
                  <div
                    className='accordion-item wow fadeInDown'
                    data-wow-duration='0.4s'
                    data-wow-delay='0.4s'
                  >
                    <h2 className='accordion-header' id='headingOne'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseOne'
                        aria-expanded='false'
                        aria-controls='collapseOne'
                      >
                        <img src={faqinfo} alt='' className='icon' />
                        Czy uczestnictwo w Hackathon'ie jest płatna?
                      </button>
                    </h2>
                    <div
                      id='collapseOne'
                      className='accordion-collapse collapse'
                      aria-labelledby='headingOne'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                        udział w tym wydarzeniu jest bezpłatny. Jeśli chciałbyś
                        wziąć w nim udział, zachęcamy na{' '}
                        <a href='https://app.evenea.pl/event/art-of-product-building/'>
                          zapisanie się{' '}
                        </a>{' '}
                        do wydarzenia.
                      </div>
                    </div>
                  </div>
                  <div
                    className='accordion-item wow fadeInDown'
                    data-wow-duration='0.4s'
                    data-wow-delay='0.4s'
                  >
                    <h2 className='accordion-header' id='headingTwo'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseTwo'
                        aria-expanded='false'
                        aria-controls='collapseTwo'
                      >
                        <img src={faqinfo} alt='' className='icon' />
                        Jakie są główne cele hackathonów?
                      </button>
                    </h2>
                    <div
                      id='collapseTwo'
                      className='accordion-collapse collapse'
                      aria-labelledby='headingTwo'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                        Celem hackathonów jest Zdobycie wiedzy i praktycznego
                        doświadczenia jak tworzyć produkty. Szczególnie istotne
                        dla zespołów deweloperskich, które będą tworzyć produkty
                        dla organizacji oraz osób które chcą rozpocząć swój
                        własny startup.
                      </div>
                    </div>
                  </div>
                  <div
                    className='accordion-item wow fadeInDown'
                    data-wow-duration='0.4s'
                    data-wow-delay='0.4s'
                  >
                    <h2 className='accordion-header' id='headingSecond'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#headingSecond'
                        aria-expanded='false'
                        aria-controls='headingSecond'
                      >
                        <img src={faqinfo} alt='' className='icon' />
                        Czy będą dostępne posiłki podczas trwania hackathonu?
                        {/* <ArrowForwardIosIcon /> */}
                      </button>
                    </h2>
                    <div
                      id='headingSecond'
                      className='accordion-collapse collapse'
                      aria-labelledby='headingSecond'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                        Tak, na wydarzeniu dla uczestników będzie dostępna pizza
                        🍕 Dodatkowo stale będzie dostępna oczywiście kawa,
                        herbata oraz przekąski
                      </div>
                    </div>
                  </div>
                  <div
                    className='accordion-item wow fadeInDown'
                    data-wow-duration='0.4s'
                    data-wow-delay='0.4s'
                  >
                    <h2 className='accordion-header' id='headingFour'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseFour'
                        aria-expanded='false'
                        aria-controls='collapseFour'
                      >
                        <img src={faqinfo} alt='' className='icon' />
                        Kiedy i gdzie odbędzie się hackathon?
                      </button>
                    </h2>
                    <div
                      id='collapseFour'
                      className='accordion-collapse collapse'
                      aria-labelledby='headingFour'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                        Na uczelni WSIiZ w Rzeszowie.{' '}
                        <a href='https://goo.gl/maps/xKSjkaRTHuMBZJD88'>
                          Sucharskiego 2, 35-225 Rzeszów
                        </a>
                        , Jeśli chcesz wziąć udział, zapraszamy do zgłoszenia
                        się na{' '}
                        <a href='https://app.evenea.pl/event/art-of-product-building/'>
                          stronie z zapisem
                        </a>{' '}
                        na wydarzenie.
                      </div>
                    </div>
                  </div>
                  <div
                    className='accordion-item wow fadeInDown'
                    data-wow-duration='0.4s'
                    data-wow-delay='0.4s'
                  >
                    <h2 className='accordion-header' id='headingFive'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseFive'
                        aria-expanded='false'
                        aria-controls='collapseFive'
                      >
                        <img src={faqinfo} alt='' className='icon' />
                        Jakie są wymagania, aby wziąć udział w hackathonie?
                      </button>
                    </h2>
                    <div
                      id='collapseFive'
                      className='accordion-collapse collapse'
                      aria-labelledby='headingFive'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                        Wymagania różnią się w zależności od wydarzenia, ale
                        najczęściej wymagane są podstawowe umiejętności
                        programowania oraz zaangażowanie w tworzenie projektu.
                      </div>
                    </div>
                  </div>
                  <div
                    className='accordion-item wow fadeInDown'
                    data-wow-duration='0.4s'
                    data-wow-delay='0.4s'
                  >
                    <h2 className='accordion-header' id='headingSix'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseSix'
                        aria-expanded='false'
                        aria-controls='collapseSix'
                      >
                        <img src={faqinfo} alt='' className='icon' />
                        Jaka będzie główna tematyka na hackathon'ie?
                      </button>
                    </h2>
                    <div
                      id='collapseSix'
                      className='accordion-collapse collapse'
                      aria-labelledby='headingSix'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                        <b>Głównymi tematykami będą:</b>
                        <ul className='default-list'>
                          <li className='list-item'>Rozwiązania Smart City</li>
                          <li className='list-item'>Mobility</li>
                          <li className='list-item'>
                            Innowacyjne rozwiązania dla biznesu
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='sponsors-container'>
        <div className='section-head'>
          <h3
            className='subtitle wow fadeInDown'
            data-wow-duration='0.4s'
            data-wow-delay='0.4s'
            id='subtitle-contact'
          >
            Nasi sponsorzy
          </h3>
          <div className='sponsorsContent'>
            {[
              {
                logo: cetusLogo,
                alt: 'Cetus',
                link: 'https://cetuspro.com/',
              },
              {
                logo: logoMobitouch,
                alt: 'Mobitouch',
                link: 'https://mobitouch.net/',
              },
              {
                logo: logoSoftwaremind,
                alt: 'softwaremind',
                link: 'https://softwaremind.com/',
              },
              { logo: neti, alt: 'neti', link: 'https://www.neti-soft.com/' },
              { logo: logoWsiz, alt: 'Wsiz', link: 'https://wsiz.edu.pl/' },
              {
                logo: logoDevCodi,
                alt: 'Devcodi',
                link: 'https://devcodi.com/',
              },
              {
                logo: logoDivstack,
                alt: 'Divstack',
                link: 'https://www.divstack.pl/',
              },
            ].map((sponsor, index) => (
              <div className='singleSponsor' key={index}>
                <a href={sponsor.link} target='_blank'>
                  <img src={sponsor.logo} alt={sponsor.alt} />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='container wrapper-contact' id='kontakt-section'>
        <div className='section-head'>
          <h3
            className='subtitle wow fadeInDown'
            data-wow-duration='0.4s'
            data-wow-delay='0.4s'
            id='subtitle-contact'
          >
            Kontakt
          </h3>
          <h2
            className='title wow fadeInDown'
            data-wow-duration='0.4s'
            data-wow-delay='0.4s'
          >
            Skontaktuj się z nami <br />
          </h2>
          <p
            className='text wow fadeInDown contact-text-fixed'
            data-wow-duration='0.4s'
            data-wow-delay='0.4s'
          >
            Skontaktuj się z nami gdy nie udało ci się znaleźć odpowiedzi w{' '}
            <a href='#faq'>Często Zadawanych Pytaniach</a>, lub jeśli
            potrzebujesz pomocy.
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id='contact_form'
          className='contact_form'
        >
          <div className='row'>
            <div className='input-group'>
              <div className='input-group--label'>Podaj imię / nazwę firmy</div>
              <input
                {...register('first_name', {
                  required: 'To pole jest wymagane',
                  pattern: {
                    value: /^[^\d]+$/,
                    message: 'To pole nie może zawierać liczb',
                  },
                })}
                type='text'
                name='first_name'
                id='name'
                placeholder='Podaj imię / nazwę firmy (wymagane)'
              />
              {errors.first_name && (
                <div className='messages'>{errors.first_name?.message}</div>
              )}
            </div>
          </div>
          <div className='input-group'>
            <div className='input-group--label'>Podaj E-mail</div>
            <input
              {...register('email_adress', {
                required: 'To pole jest wymagane',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'To pole musi być adresem email, musi zawierać @',
                },
              })}
              type='text'
              name='email_adress'
              id='email'
              placeholder='Podaj adres e-mail (wymagane)'
            />
            {errors.email_adress && (
              <div className='messages'>{errors.email_adress?.message}</div>
            )}
            <div className='messages'></div>
          </div>
          <div className='input-group'>
            <div className='input-group--label'>Podaj numer telefonu</div>
            <input
              {...register('phone_number', {
                required: false,
                pattern: {
                  value: /^[0-9]{9}$/,
                  message: 'To pole musi zawierać 9 cyfr',
                },
              })}
              type='tel'
              name='phone_number'
              id='nr_phone'
              //   id='number'
              placeholder='Podaj numer telefonu'
            />
            {errors.phone_number && (
              <div className='messages'>{errors.phone_number.message}</div>
            )}
          </div>
          <div className='input-group'>
            <div className='input-group--label'>Wiadomość</div>
            <textarea
              id='message'
              {...register('message', {
                required: 'To pole nie może być puste',
                minLength: {
                  value: 8,
                  message: 'Wiadomość musi mieć przynajmniej 10 znaków',
                },
                maxLength: {
                  value: 500,
                  message: 'Wiadomość może mieć maksymalnie 500 znaków',
                },
              })}
              //   name='description'
              cols='30'
              rows='10'
              placeholder='Twoja wiadomość (wymagane)'
              name='message'
            ></textarea>
            {errors.message && (
              <div className='messages'>{errors.message?.message}</div>
            )}
          </div>
          <div
            className='g-recaptcha'
            data-sitekey='6LdTHVgdAAAAAPjxvRJa7udfo0IFOmaEdK4Vne4I'
          ></div>
          <div className='input-group btn-container'>
            <button className='btn btn--normal' id='submit-btn' type='submit'>
              <span className='submit-btn-text'>
                <StyledSendIcon /> Wyślij
              </span>
            </button>
          </div>
        </form>
      </div>
      <div
        id='scrollUp'
        title='Scroll To Top'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <StyledArrowUpIcon />
      </div>
    </main>
  );
};

export default Main;
