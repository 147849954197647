import { styled } from '@mui/system';
import LoginIcon from '@mui/icons-material/Login';
import SendIcon from '@mui/icons-material/Send';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const StyledLoginIcon = styled(LoginIcon)({
  fontSize: '25px',
  marginBottom: '3px',
});
export const StyledSendIcon = styled(SendIcon)({
  fontSize: '23px',
});
export const StyledArrowUpIcon = styled(ArrowUpwardIcon)({
  color: 'white',
  fontSize: 28,
});
export const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)({
  fontSize: 20,
  marginTop: -4,
});
